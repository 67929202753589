// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-busqueda-js": () => import("./../../../src/pages/busqueda.js" /* webpackChunkName: "component---src-pages-busqueda-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-entretenimiento-js": () => import("./../../../src/templates/entretenimiento.js" /* webpackChunkName: "component---src-templates-entretenimiento-js" */),
  "component---src-templates-geek-js": () => import("./../../../src/templates/geek.js" /* webpackChunkName: "component---src-templates-geek-js" */),
  "component---src-templates-peliculas-js": () => import("./../../../src/templates/peliculas.js" /* webpackChunkName: "component---src-templates-peliculas-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */),
  "component---src-templates-videojuegos-js": () => import("./../../../src/templates/videojuegos.js" /* webpackChunkName: "component---src-templates-videojuegos-js" */)
}

